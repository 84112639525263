<template>
	<div class="textmessage">
		<el-card>
			<div class="global-search-single">
				<div class="single-left">
					<el-button type="primary" @click="edit" v-if="hasPerm(['delivery.rules.store'])">添加包邮规则</el-button>
				</div>
			</div>
			<div class="textmessage_bottom">
				<div class="textmessage_bottom_02">
					<el-table :data="templateData">
						<el-table-column prop="id" label="ID" width="15%" align="left"></el-table-column>
						<el-table-column prop="price" label="金额" width="15%" align="left"></el-table-column>
						<el-table-column prop="date" label="城市" width="55%">
							<template slot-scope="scope">
								<span >{{ addressResourceMap2String(scope.row.city) }}</span>
								
								<!-- {{ scope.row.province_list.map(item => item.name).join(",") }} -->
							</template>
						</el-table-column>
						<el-table-column label="操作" width="15%" align="right">
							<template slot-scope="scope">
								<div class="caozuo">
									<a @click="handleModify(scope)" v-if="hasPerm(['delivery.rules.update'])">编辑</a>
									<a @click="handleDelete(scope)" v-if="hasPerm(['delivery.rules.destroy'])">删除</a>
								</div>
							</template>
						</el-table-column>
						<template slot="empty">
							<div class="empty_data">
								<p>暂无数据</p>
							</div>
						</template>
					</el-table>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {Message} from 'element-ui';
	import common from '../../../../common/common';
	export default {
		data() {
			return {
				templateData: [],
				searchObj: {
					search: [],
					order: [{
						key: 'id',
						value: 'desc'
					}]
				}
			};
		},
		created() {
			this.getTemplateInfo();
		},
		methods: {
			// 将省市区数据转为地址拼接
			addressResourceMap2String(addArray) {
				return addArray.reduce((pre, item, index) => {
					pre +=`${item.name} ${((item.list && item.list.length > 0) && '(' + this.addressResourceMap2String(item.list) + ')') || ""}`
					return pre
				}, "")
			},
			// 获取模版信息
			getTemplateInfo() {
				// this.$set(this.searchObj, 'search', this.common.handleSearch(this.templateForm, 'search'));
				this.$get(this.$apis.parcelapi, {...this.searchObj}).then(res => {
					if (res.code == 200) {
						// res.data.some((item,index)=>{
						// 	// console.log(item.city);
						// 	item.city.some((i,b)=>{
						// 		console.log(this.addressResourceMap2String(i.province_list,'457454'))
						// 	})
						// 	// 
						// })
						this.$set(this, 'templateData', res.data.list)
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 编辑
			handleModify(id) {
				this.$router.push({
					path: "/setup/yfparcel",
					query: {
						id:id.row.id
					}
				})
			},
			// 删除
			handleDelete(id) {
				this.$confirm(`确定要将该条数据删除吗？`, "警告", {type: "warning"}).then(() => {
					this.$delete(this.$apis.parceldelect + id.row.id).then(res => {
						if (res.code == 200) {
							Message.success("删除成功!");
							this.getTemplateInfo();
						} else {
							Message.error(res.message);
						}
					})
				}).catch(err => {
					Message[((() => err == 'cancel' ? 'info' : 'error'))()]({
						message: err == 'cancel' ? '已取消删除!' : err
					})
				})
			},

			edit() {
				this.$router.push("/setup/yfparcel")
			}
		}
	};
</script>

<style lang="less" scoped>
	.textmessage_bottom_02 /deep/ .el-table__empty-block{width: 100% !important;}
	.caozuo a {
		padding: 0 10px;
		border-right: 1px solid var(--fontColor, #fb6638);
		cursor: pointer;
		display: inline-block;
		line-height: 1;
		color: var(--fontColor);
	}
	
	.caozuo a:last-child {
		border-right: none;
	}
	.textmessage {
		.textmessage_bottom {
			border-radius: 4px;
			background: #fff;
			padding-top: 24px;
			box-sizing: border-box;

			.textmessage_bottom_01 {
				width: 100%;
				height: 62px;
				display: flex;
				justify-content: space-between;

				// margin-bottom: -33px;
				& /deep/.el-form-item__label {
					width: 70px;
					// height: 16px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #666666;
					// line-height: 16px;
				}

				& /deep/.el-input__inner {
					width: 240px;
					height: 40px;
					background: #FFFFFF;
					border-radius: 4px;
					border: 1px solid #DDDFE6;
				}
			}

			.textmessage_bottom_02 {
				width: 100%;

				& /deep/.el-table {
					& .el-table__header {
						width: 100% !important;
						& tr {
							width: 100%;
							th{
								background: #f9f9fc;
							}
							& .cell {
								width: 100% !important;
								padding: 0 16px;
								box-sizing: border-box;
								font-size: 14px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: bold;
								color: #333333;
							}
						}
					}

					& .el-table__body {
						width: 100% !important;

						& .cell {
							width: 100% !important;
							padding: 0 16px;
							box-sizing: border-box;
						}
					}

					& th.el-table__cell {
						font-size: 14px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #333333;
						background-color: #fff;

						&:nth-of-type(2) {
							height: 56px;
						}
					}

					& td.el-table__cell {
						&:nth-of-type(2) {
							height: 76px;
						}
					}
				}
			}

			.goodsbtn {
				width: 116px;
				height: 38px;
				background: #000D9A;
				border-radius: 4px;
				text-align: center;
				padding: 0;
			}

			.bottom-bat {
				.bottom-bat-tab {
					height: 50px;
					background: #f9f9fc;
				}
			}
		}

		.addtion_foot {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			padding-left: 160px;
			box-sizing: border-box;
			height: auto;
			border-top: 1px solid #f1f1f1;
			z-index: 6;
			background-color: #fff;

			.addtion_foot-body {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px !important;
			}
		}
	}
</style>
